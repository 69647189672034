import AuthService from '@/app/services/auth'
import { ErrorResponse } from '@/types/api'
import { useMutation, useQueryClient } from 'react-query'
import Cookies from 'js-cookie'
import { USER_INFO_COOKIE_NAME } from '@/app/lib/constants'

export function useAuthLogout() {
  const queryClient = useQueryClient()

  return useMutation<void, ErrorResponse, void>(
    AuthService.POST_PATH_LOGOUT,
    AuthService.logout,
    {
      onSuccess: async () => {
        Cookies.remove(USER_INFO_COOKIE_NAME)
        await queryClient.cancelQueries()
        queryClient.clear()
      },
    }
  )
}
